import React, { Component } from 'react';
import { navigate } from "gatsby";
import axios from '../../helper/Axios';
import classnames from 'classnames';
import amber_logo_blue from '../../images/amber-logo-blue.png';
import { NotificationManager } from 'react-notifications';
import {looseFocus} from '../../helper/LooseFocus';
import jwt_decode from 'jwt-decode';
import axios_default from 'axios';
import config from '../../../config';

export default class UnsubscirbeEmail extends Component {

    state = {
        unsubscribe_types: {
            unsubscribe: {title: 'Daily Email'},
            unsubscribe_celebration_email : {title: 'Celebration Email'},
            unsubscribe_fortnightly : {title: 'Fortnightly Email'},
            unsubscribe_weekly_report : {title: 'Weekly Report'},
            unsubscribe_quarterly_report : {title: 'Quarterly Report'},
            unsubscribe_exit: {title: 'Exit Email'},
            unsubscribe_highlights: {title: 'Highlights'},
            unsubscribe_textsens_email: {title: 'TextSens Real-Time Updates'},
            crisis_daily_highlights: {title: 'Crisis Updates'},
            unsubscribe_manager_nudges: {title: 'Crisis Manager Report'},
            unsubscribe_weekly_highlights: {title: 'Weekly Highlights'}
        },
        selected_type: '',
        subscribe_type: 1, // 1 - unsubscribe, 0 - subscribe,
        loading: false,
        user_id: '',
        reasons: [],
        other_reason_active: false,
        other_reason: '',
        unsubscribed: false
    }

    // If no token go back to home page
    // --------------------------------
    componentDidMount(){
        if(!sessionStorage.token){
            navigate('/');
        } else {
            const user_id = jwt_decode((sessionStorage.token)).id;
            this.setState({selected_type: sessionStorage.unsubscription_type, user_id, unsubscribed: sessionStorage.getItem('unsubscribed') ? sessionStorage.getItem('unsubscribed') : false});
        }
    }

    // Subscribe or Unsubscirbe
    // ------------------------
    subscribeUnsubsribeCall = () =>{
        const { selected_type, subscribe_type, reasons, other_reason } = this.state;
        let all_reasons = reasons.length ? reasons.join() : '' + other_reason ? other_reason : ''
        looseFocus();
        switch(selected_type){
            case `unsubscribe_celebration_email` :  
                this.subscriptionCall('unsubscribe_celebration_email', {'unsubscribe_celebration_email': subscribe_type}, false);
                break;
            case `unsubscribe_fortnightly` :  
                this.subscriptionCall('unsubscribe_fortnightly', {'unsubscribe': subscribe_type}, false);
                break;
            case `unsubscribe_weekly_report` :  
                this.subscriptionCall('unsubscribe_weekly_report', {'unsubscribe': subscribe_type}, true);
                break;
            case `unsubscribe_quarterly_report` : 
                this.subscriptionCall('unsubscribe_quarterly_report', {'unsubscribe': subscribe_type}, true);
                break;
            case `unsubscribe_exit` :  
                this.subscriptionCall('unsubscribe_exit', {'unsubscribe': subscribe_type}, false);
                break;
            case `unsubscribe` :  
                this.subscriptionCall('unsubscribe', {'unsubscribe': subscribe_type}, false);
                break;
            case `unsubscribe_highlights` :  
                this.subscriptionCall('unsubscribe_highlights', {'unsubscription_type': 'unsubscribe_highlights', 'unsubscribe_reason': all_reasons}, false);
                break;
            case `unsubscribe_weekly_highlights` :  
                this.subscriptionCall('unsubscribe_weekly_highlights', {'unsubscription_type': 'unsubscribe_weekly_highlights', 'unsubscribe_reason': all_reasons}, false);
                break;
            case `unsubscribe_textsens_email`:
                this.subscriptionCall('unsubscribe_textsens_email', {'unsubscribe_textsens_email': subscribe_type}, false);
                break;
            case `crisis_daily_highlights`:
                this.subscriptionCall('unsubscribe_highlights', {'unsubscription_type': 'crisis_daily_highlights', 'unsubscribe_reason': all_reasons}, false);
                break;
            case `unsubscribe_manager_nudges`:
                this.subscriptionCall('unsubscribe_manager_nudges', {'unsubscribe': subscribe_type}, false);
                break;
            default:
                this.subscriptionCall('unsubscribe', {'unsubscribe': subscribe_type}, false);
                break;
        }
    }

    // Subscription API call
    // ---------------------
    subscriptionCall = async (api, patch, is_manager) => {
        const { subscribe_type, user_id } = this.state;
        this.setState({loading: true});
        let endpoint_url = (is_manager 
            ? `/v1/manager_access/${sessionStorage.manager_access_id}/${api}` 
            : api !== 'unsubscribe_highlights' 
                ? api !== 'unsubscribe_weekly_highlights' ? `/v1/users/${user_id}/${api}` : 
                `/v1/subscriptions/unsubscribe?is_accessible=true` : `/v1/subscriptions/unsubscribe?is_accessible=true`
            );
        if(api === `unsubscribe_manager_nudges`){
            endpoint_url = `/v1/manager_persona/crisis_management/unsubscribe`;
        }
        try {
            if(api === 'unsubscribe_exit'){
                endpoint_url = `${config.exit_url}v1/users/${user_id}/${api}`;
                const headers = {Authorization : `Bearer ${sessionStorage.token}`};
                await axios_default.patch(endpoint_url, patch, {headers});
            } else if (api === 'unsubscribe_highlights') {
                await axios.post(endpoint_url, patch);
            } else if (api === 'unsubscribe_weekly_highlights') {
                await axios.post(endpoint_url, patch);
            } else {                
                await axios.patch(endpoint_url, patch);
            }
            NotificationManager.success((subscribe_type === 0 ? 'Subscribed Successfully' : 'Unsubscribed Successfully'));
            sessionStorage.setItem('unsubscribed', true)
            this.setState({subscribe_type: (subscribe_type === 1 ? 0 : 1), loading: false, unsubscribed: true});
        } catch (error) {
            if(error.response.status === 401){
                NotificationManager.error('You have already used this link to unsubscribe');
             }
            else {
                NotificationManager.error(error.response.data.message || 'Something went wrong');
            }
        }
        this.setState({loading: false});
    }

    addReason = (reason) => {
        let {reasons} = this.state;
        if(reasons.length && reasons.indexOf(reason) > -1) {
            reasons.splice(reasons.indexOf(reason), 1)
        } else {
            reasons.push(reason)
        }

        this.setState({reasons})
    }

    setOtherReason = () => {
        this.setState({other_reason_active: !this.state.other_reason_active})
    }

    handleChange = (e) => {
        this.setState({other_reason: e.target.value})
    }

    render() {
        const { unsubscribe_types, selected_type, loading, subscribe_type, reasons, other_reason, other_reason_active, unsubscribed } = this.state;
        return (
            <div>
               <div className="columns pd-25">
                    <div className="column is-4 mar-auto" onSubmit={this.login}>
                        <figure className="app-logo">
                            <img src={amber_logo_blue} width="130px" alt="amber logo"/>
                        </figure>
                        <h1 className="login-heading">Manage Subscription: {selected_type.length ? unsubscribe_types[selected_type].title : null}</h1>
                        <div className="font-14">
                        {selected_type !== 'unsubscribe_highlights' && selected_type !== 'crisis_daily_highlights' ? (subscribe_type === 1 
                        ? <span>If you wish to <b>unsubscribe</b> from <span className="has-text-weight-semibold">{selected_type.length ? unsubscribe_types[selected_type].title : null}</span> click the button below. To keep your subscription active, simply close this window.</span> 
                        : <span>If you wish to <b>subscibe</b> to <span className="has-text-weight-semibold">{selected_type.length ? unsubscribe_types[selected_type].title : null}</span> click the button below, To keep your subscription deactivated, simply close this window.</span>) 
                        : !unsubscribed ?
                            <div className="level highlights-reasons">
                                Please provide us a reason: 
                                <div className="dropdown is-hoverable mg-left-1 width-206">
                                    <div className="dropdown-trigger">
                                        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                                        <span className="font-14">{reasons.length ? `Reasons Selected (${reasons.length})` : reasons.length && other_reason_active ? `Reasons Selected (${reasons.length})` : !reasons.length && other_reason_active ? 'Other Reason' : 'Reason to unsubscribe'}</span>
                                        <span className="icon is-small">
                                            <i className={classnames('fas fa-angle-down')} aria-hidden="true"></i>
                                        </span>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                                        <div className="dropdown-content">
                                            <div onClick={this.addReason.bind(this, `It's too frequent for me`)} className={classnames('dropdown-item pointer')}>
                                                <input checked={reasons.includes(`It's too frequent for me`)} type="checkbox"/> It's too frequent for me
                                            </div>
                                            <hr className="dropdown-divider"/>
                                            <div onClick={this.addReason.bind(this, `It's not actionable`)} className="dropdown-item pointer">
                                                <input checked={reasons.includes(`It's not actionable`)} type="checkbox"/> It's not actionable
                                            </div>
                                            <hr className="dropdown-divider"/>
                                            <div onClick={this.addReason.bind(this, `There's too much information in this email`)} className="dropdown-item pointer">
                                                <input checked={reasons.includes(`There's too much information in this email`)} type="checkbox"/> There's too much information in this email
                                            </div>
                                            <hr className="dropdown-divider"/>
                                            <div onClick={this.setOtherReason} className={classnames('dropdown-item pointer', {'is-active': other_reason_active})}>
                                                Other
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : 
                                <p>
                                    We are sorry to see you go. If you'd like to re-subscribe in future, please email at <a href="mailto:help@infeedo.com">help@infeedo.com</a>
                                </p>
                            }
                            {
                                other_reason_active && !unsubscribed ? 
                                <div className="field">
                                    <div className="control">
                                        <input autoFocus className="input font-14" type="text" placeholder="Please share your reason..." value={other_reason} onChange={this.handleChange}/>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        <br/>
                        {
                            (selected_type === 'unsubscribe_highlights' || selected_type === 'crisis_daily_highlights') && unsubscribed ? null :
                            <p className="control has-text-right">
                                <button disabled={(selected_type === 'unsubscribe_highlights' || selected_type === 'crisis_daily_highlights') && (!reasons.length && (!other_reason_active))} type="button" onClick={this.subscribeUnsubsribeCall} className={classnames('button', {'is-loading': loading, 'is-danger': subscribe_type === 1, 'is-link': subscribe_type === 0})}>
                                &nbsp;&nbsp;{(subscribe_type === 1 ? 'Unsubscribe' : 'Subscribe')}&nbsp;&nbsp;
                                </button>
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
